<template>
  <ServiceLayout>
    <v-sheet width="370" class="px-6 py-8" rounded>
      <v-form
        ref="form"
        @submit.prevent="authenticate"
        @keyup.enter="authenticate"
        v-model="valid"
        lazy-validation
      >
        <h6 class="text-h6 text-center mb-7">Zaloguj się</h6>
        <v-text-field
          :rules="$rules.required"
          v-model="credentials.email"
          outlined
          dense
          label="Email"
          type="email"
          name="email"
        />
        <PasswordInput
          :rules="$rules.required"
          v-model="credentials.password"
          outlined
          dense
          label="Hasło"
          type="password"
          name="password"
        />
        <v-checkbox v-model="saveSession">
          <template v-slot:label>
            <span class="text-caption"> Zapamiętaj mnie </span>
          </template>
        </v-checkbox>
        <v-btn :loading="loading" type="submit" large rounded block color="primary"
          >Zaloguj się</v-btn
        >
        <router-link
          class="text-decoration-none text--gray d-block mt-7 text-center"
          :to="{path: '/forgot-password'}"
        >
          Nie pamiętasz hasła?
        </router-link>
      </v-form>
    </v-sheet>
  </ServiceLayout>
</template>

<script>
import PasswordInput from '@/components/UI/PasswordInput'
import {mapActions} from 'vuex'
import ServiceLayout from '@/layouts/ServiceLayout'

export default {
  name: 'Login',
  data() {
    return {
      valid: true,
      loading: false,
      saveSession: false,
      credentials: {
        email: '',
        password: '',
      },
    }
  },
  components: {
    ServiceLayout,
    PasswordInput,
  },
  methods: {
    ...mapActions(['login', 'showNotification']),
    async authenticate() {
      try {
        if (this.$refs.form.validate()) {
          this.loading = true
          await this.login({
            credentials: this.credentials,
            saveSession: this.saveSession,
          })
          this.$router.push({path: '/'})
        }
      } catch (error) {
        this.showNotification({
          message: 'NOTY_WRONG_CREDENTIALS',
          type: 'error',
        })
        this.credentials.password = ''
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
.text--gray {
  color: #717171;
}
</style>
